import './App.css';
import { React, useEffect } from 'react';
import Homepage from './pages/Homepage/Homepage';
import Trivio from './pages/Trivio/Trivio';
import Speakeasy from './pages/Speakeasy/Speakeasy';
import AboutMe from './pages/About Me/AboutMe';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={ <Homepage/> } />
        <Route exact path="/projects/trivio" element={ <Trivio/> } />
        <Route exact path="/projects/speakeasy" element={ <Speakeasy/> } />
        <Route exact path="/about-me" element={ <AboutMe/> } />
      </Routes>
    </Router>
  );
}

export default App;
