import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar = (props) => {

  const borderBottomValue = window.location.pathname.includes("/projects/") ? '' : '1px solid rgba(255, 255, 255, 0.2)';
  
  const onPage = (name) => {
    return window.location.pathname === "/" + name
  };

  return (
    <nav className="navbar" style={{
      'backgroundColor': `${props.backgroundColor}`
    }}>
      <div className="margins-container" style={{maxWidth: onPage("") ? '80rem' : '90rem', margin: 'auto'}}>
        <div className="wrapper" style={{
          'borderBottom': borderBottomValue,
        }}>
          <Link to="/" className="my-name">David Chen</Link>
          <div className="nav-links">
            <Link style={{opacity: onPage("about-me") ? 1 : 0.6}} to="/about-me" className="nav-link">About Me</Link>
            <Link style={{opacity: onPage("") ? 1 : 0.6}} to="/" className="nav-link">Projects</Link>
            <a 
              className="nav-link"
              href="https://drive.google.com/file/d/1QMC6TdpyjagAQPx5e9LUMKRxYoUEHN8k/view?usp=sharing"
              style={{
                opacity: onPage("resume") ? 1 : 0.6
              }}
            >
              <p>Resume</p>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
