import React from 'react';
import NavigationBar from '../../shared/NavigationBar/NavigationBar';
import Footer from '../../shared/Footer/Footer';
import AboutMeImages from '../../photos/About/AboutMeImages.png';
import './AboutMe.css';

const AboutMe = () => {
  return (
    <div className="about-me">
      <NavigationBar backgroundColor={"var(--speakeasy-bg)"}/>
      <div className="content">
        <div className="lhs">
          <h3>Who I am (as a Designer)</h3>
          <p>
          I've spent my entire life rejecting an inner pull towards the 
          humanities to satisfy the pragmatic vision of having a career 
          in the hard sciences. I won two essay competitions in elementary 
          school, started a fashion club in high school, had both of my 
          college letters of recommendation written by humanities teachers 
          (my STEM teachers didn't like me as much), founded a travel 
          magazine in college, all to graduate with a bachelor's in data 
          science and pursue a career in software engineering.<br/><br/>

          Despite the apparent incongruence between my inner passions and
          my professional ambitions, I don't regret being pulled into the 
          hard sciences. My abilities in solving technical problems coupled 
          with my desire to find and create beauty around me have led me 
          to digital product design, a field I have danced around for years 
          before fully committing in the past few months.<br/><br/>

          My strengths as a designer are threefold: a sensibility for 
          product, experience in intuitive design, and ability to execute 
          via engineering. You can explore my application of these strengths 
          by reading the case studies featured in this portfolio.<br/><br/>
          </p>
        </div>
        <img src={AboutMeImages} alt="Three photos related to me"/>
      </div>
      <Footer footerColor="var(--speakeasy-footer)"/>
    </div>
  );
};

export default AboutMe;