import React, { useEffect, useRef, useState } from 'react';
import './Homepage.css';
import NavigationBar from '../../shared/NavigationBar/NavigationBar'
import Footer from '../../shared/Footer/Footer'

import SimpleArrowForward from '../../photos/Shared Icons/SimpleArrowForward.png';
import TrivioMobilePreview1 from '../../photos/Homepage/TrivioMobilePreview1.png';
import TrivioMobilePreview2 from '../../photos/Homepage/TrivioMobilePreview2.png';
import TrivioGameCodePreview from '../../photos/Homepage/TrivioGameCodePreview.png';
import TrivioBuzzerPreview from '../../photos/Homepage/TrivioBuzzerPreview.png';
import TrivioDesktopPreview from '../../photos/Homepage/TrivioDesktopPreview.png';

import SpeakeasyPreview from '../../photos/Homepage/SpeakeasyPreview.png';

import CaravanCoverBG from '../../photos/Homepage/CaravanCoverBG.JPG';
import CaravanCoverPage from '../../photos/Homepage/CaravanCoverPage.png';

import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const navigate = useNavigate();

  const goToTrivio = () => {
    navigate('/projects/trivio');
  };
  
  const goToSpeakeasy = () => {
    navigate('/projects/speakeasy');
  };

  const [activeProject, setActiveProject] = useState(null);  // Add this line
  const snapContainerRef = useRef(null);
  const activeBGColor = () => {
    if (activeProject === "trivio") {
      return "var(--trivio-bg)"
    } else if (activeProject === "speakeasy") {
      return "var(--speakeasy-bg)"
    } else {
      return "var(--caravan-bg)"
    };
  };
  const activeFooterColor = () => {
    if (activeProject === "trivio") {
      return "var(--trivio-footer)"
    } else if (activeProject === "speakeasy") {
      return "var(--speakeasy-footer)"
    } else {
      return "var(--caravan-footer)"
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = snapContainerRef.current;
      const sections = Array.from(container.querySelectorAll('.snap-section'));
      let activeSection = null;
      for (const section of sections) {
        const { top } = section.getBoundingClientRect();
        if (top <= window.innerHeight / 2 && top >= -window.innerHeight / 2) {
          activeSection = section;
          break;
        }
      }
    
      if (activeSection) {
        const id = activeSection.id;
        setActiveProject(id);  // Update the state here
      }
    };

    handleScroll();

    const container = snapContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div 
      className="homepage" 
      style={{ backgroundColor: activeBGColor() }}>
      <div className="nav-bar-container">
        <NavigationBar backgroundColor={activeBGColor()}/>
      </div>
      <div className="homepage-container" ref={snapContainerRef}>
        <div className="featured-projects">
          <div className="snap-section" id="trivio">
            <div 
              className="trivio-feature" 
              onClick={goToTrivio}
              style={{
                borderTop: `70px solid ` + activeBGColor(),
                opacity: activeProject === 'trivio' ? 1 : 0.2,
              }}
            >
              <div className="left-container">
                <div className="cover-titles">
                  <p className="eyebrow">MOBILE APP + WEB DESIGN</p>
                  <h1>Trivio, Jeopardy App</h1>
                  <div className="trivio-subtitle">
                    <span style={{ opacity: 0.6 }}>Original mobile and web app with </span>
                    <b style={{ opacity: 1 }}>5,000 iOS users </b>
                    <span style={{ opacity: 0.6 }}>and counting. Story told in </span>
                    <b style={{ opacity: 1 }}>two acts.</b>
                  </div>
                  <div className="read-cta">
                    <p style={{'fontSize': '20px'}}>Read the Case Study</p>
                    <img src={SimpleArrowForward} alt="Arrow Forward" />
                  </div>
                </div>
                <div className="mobile-previews">
                  <img src={TrivioMobilePreview1} alt="Trivio Mobile Board" />
                  <img src={TrivioMobilePreview2} alt="Trivio Mobile Clue" />
                  <div className="homescreen-annotations">
                    <h2>Mobile Design</h2>
                    <p>Act One covers the existing iOS App</p>
                  </div>
                </div>
              </div>
              <div className="desktop-previews">
                <div className="lhs">
                  <div className="mobile-screens">
                    <img src={TrivioGameCodePreview} alt="Trivio Code Entry" />
                    <img src={TrivioBuzzerPreview} alt="Trivio Active Buzzer" style={{'marginLeft': '20px'}}/>
                  </div>
                  <div className="homescreen-annotations">
                    <h2>Web Design</h2>
                    <p>Act Two covers future plans</p>
                  </div>
                </div>
                <img 
                  className="notebook-mockup"
                  src={TrivioDesktopPreview} 
                  alt="Trivio Code Entry"   
                />
              </div>
            </div>
          </div>
          <div className="snap-section" id="speakeasy">
            <div 
              className="speakeasy-feature" 
              onClick={goToSpeakeasy}
              style={{
                borderTop: `70px solid ` + activeBGColor(),
                opacity: activeProject === 'speakeasy' ? 1 : 0.2,
              }}
            >
              <div className="speakeasy-titles">
                <p className="eyebrow">MOBILE APP DESIGN</p>
                <h1>Speakeasy, Dating App</h1>
                <p className="speakeasy-subtitle">Designing a dating app that addresses 
                low rates of active engagement in traditional dating apps.</p>
                <div className="read-cta">
                  <p>Read the Case Study</p>
                  <img src={SimpleArrowForward} alt="Arrow Forward" />
                </div>
              </div>
              <img className="screenshots" src={SpeakeasyPreview} alt="Speakeasy App Preview Screenshots" />
            </div>
          </div>
          <div className="snap-section" id="caravan">
            <div
              className="caravan-feature" 
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url(${CaravanCoverBG})`,
                borderTop: `70px solid ` + activeBGColor(),
                opacity: activeProject === 'caravan' ? 1 : 0.2,
              }}
            >
              <img src={CaravanCoverPage} alt="Cover Page of Caravan's CA Road Trip Issue"/>
              <div className="caravan-titles">
                <p className="eyebrow">WORK IN PROGRESS</p>
                <h1>Caravan, Travel & Style Magazine</h1>
                <p style={{opacity: 0.8}}>The CA Road Trip issue, my seventh and final issue as EIC of UC 
                Berkeley's only travel magazine.</p>
                <div className="info-cta">
                  <p>Case Study Coming Soon</p>
                  <div className="circular-separator"/>
                  <a 
                    style={{textDecoration: 'none'}} 
                    href="https://drive.google.com/file/d/1NQE2Rer1Kp9LoAZc6IOSVGM4DCmZnpEQ/view?usp=sharing"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <p style={{borderBottom: '1px solid white'}}>Read the Print Issue</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer footerColor={activeFooterColor()}/>
      </div>
    </div>
  );
};

export default Homepage;
