import React from 'react';
import { Link } from 'react-router-dom';
import './Speakeasy.css';
import Footer from '../../shared/Footer/Footer';
// import NavigationBar from '../../shared/NavigationBar/NavigationBar';

import SpeakeasyPreview from '../../photos/Homepage/SpeakeasyPreview.png';
import BackgroundScreenshots from '../../photos/Speakeasy/BackgroundScreenshots.png';
import EvolutionIntroScreenshots from '../../photos/Speakeasy/EvolutionIntroScreenshots.png';
import WireframeV1 from '../../photos/Speakeasy/WireframeV1.png';
import WireframeV2 from '../../photos/Speakeasy/WireframeV2.png';
import WireframeV3 from '../../photos/Speakeasy/WireframeV3.png';
import DesignSystemHeader1 from '../../photos/Speakeasy/DesignSystemHeader1.png';
import DesignSystemHeader2 from '../../photos/Speakeasy/DesignSystemHeader2.png';
import DesignSystemMessaging from '../../photos/Speakeasy/DesignSystemMessaging.png';

// Final Tour Imports
import FinalR1Image1 from '../../photos/Speakeasy/FinalR1Image1.png';
import FinalR1Image2 from '../../photos/Speakeasy/FinalR1Image2.png';
import FinalR1Image3 from '../../photos/Speakeasy/FinalR1Image3.png';
import FinalR2Image1 from '../../photos/Speakeasy/FinalR2Image1.png';
import FinalR2Image2 from '../../photos/Speakeasy/FinalR2Image2.png';
import FinalR2Image3 from '../../photos/Speakeasy/FinalR2Image3.png';
import FinalR2Image4 from '../../photos/Speakeasy/FinalR2Image4.png';
import FinalR3Image1 from '../../photos/Speakeasy/FinalR3Image1.png';
import FinalR3Image2 from '../../photos/Speakeasy/FinalR3Image2.png';
import FinalR3Image3 from '../../photos/Speakeasy/FinalR3Image3.png';
import FinalR3Image4 from '../../photos/Speakeasy/FinalR3Image4.png';

const Speakeasy = () => {
  return (
    <div className="speakeasy-case-study">
      <div className="speakeasy-cover">
        <div className="wrapper">
          <div className="lhs-container">
            <Link to="/" className="my-name">David Chen</Link>
            <div style={{'display': 'flex', 'flex-direction': 'column'}}>
              <h1>Speakeasy, Dating App</h1>
              <p className="subtitle">Designing a dating app that addresses low rates of active 
              engagement in traditional dating apps.</p>
              <div className="cover-details">
                <div className="detail">
                  <p className="eyebrow">DURATION</p>
                  <p className="subtext">2 weeks</p>
                </div>
                <div className="detail">
                  <p className="eyebrow">ROLE</p>
                  <p className="subtext">Sole Designer</p>
                </div>
                <div className="detail">
                  <p className="eyebrow">TOOLS</p>
                  <p className="subtext">Figma, Sketch</p>
                </div>
              </div>
            </div>
          </div>
          <img className="screenshots" src={SpeakeasyPreview} alt="Screenshots of Speakeasy"/>
        </div>
      </div>
      <div className="background">
        <div className="text">
          <h3>Background</h3>
          <p>Most current dating apps follow the Tinder format, where users 
          swipe passively, matches accumulate in an inbox, and these matches 
          seldom result in meaningful conversations. <br /><br /> 
          
          Speakeasy tackles the issue of low engagement by <b>prioritizing
          conversations.</b> The app ensures instant, real-time chats by 
          limiting the matching pool to currently active users, mirroring 
          in-person dating.</p>
        </div>
      </div>
      <div className="background-screenshots">
        <img src={BackgroundScreenshots} alt="Background Screenshots" />
      </div>
      <div className="thought-process">
        <div className="margin-wrapper">
          <div className="blurb">
            <h4>Observation</h4>
            <p>Traditional dating apps suffer from poor 
            engagement after matches are made.</p>
          </div>
          <div className="blurb">
            <h4>Hypothesis</h4>
            <p>Users will engage more deeply if guaranteed 
            an active conversation with every match.</p>
          </div>
          <div className="blurb">
            <h4>Solution</h4>
            <p>We will build Speakeasy, which ensures that users 
            immediately enter real-time chats once they match.</p>
          </div>
        </div>
      </div>  
      <div className="iteration">
        <div className="content-card">
          <div className="intro">
            <img src={EvolutionIntroScreenshots} alt="Three Screenshots of Speakeasy" />
            <div className="text">
              <div className="titles">
                <h2>Evolution of the Primary Interface</h2>
                <p>Designing a place for matches to be made.</p>
              </div>
              <div className="subtext">
                <h1 style={{marginBottom: 10}}>The Bar</h1>
                <p>To communicate the feeling of being in-person at a speakeasy, 
                I decided to design a virtual “bar” that allowed users to easily 
                court others and respond to suitors, just as in a real bar. </p>
              </div>
            </div>
          </div>
          <div className="side-scroll-wireframes">
            <div className="version">
              <img src={WireframeV1} alt="Wireframe of Speakeasy's First Bar Iteration"/>
              <div className="text">
                <p className="eyebrow">VERSION 1</p>
                <h4>Likers & Grid</h4>
                <p>Displays suitors on a card atop the screen and a grid of 
                all users in the bar below. <br /><br />

                This version best conveys a physical space wherein everyone 
                present is clearly shown. <br /><br />

                Ultimately, I decided against splitting the views up by 
                suitors and others as that greatly complicates real-time 
                communication.</p>
              </div>
            </div>
            <div className="version">
              <img src={WireframeV2} alt="Wireframe of Speakeasy's Second Bar Iteration"/>
              <div className="text">
                <p className="eyebrow">VERSION 2</p>
                <h4>Scrolling View</h4>
                <p>Improves on the previous version by placing more emphasis on each 
                user in the bar.<br /><br />

                Instead of seeing suitors on a separate screen, suitors pop up in 
                real-time notifications. <br /><br />

                I moved on from this version, as seeing all profiles at once may 
                be overwhelming, and may lead to less engagement per profile. </p>
              </div>
            </div>
            <div className="version" style={{paddingRight: 50}}>
              <img src={WireframeV3} alt="Wireframe of Speakeasy's Third and Final Bar Iteration"/>
              <div className="text">
                <p className="eyebrow">VERSION 3</p>
                <h4>Slide Deck</h4>
                <p>Focuses even more attention on each profile, and increases familiarity 
                given likeness to Instagram Stories.<br /><br />

                Though this version lacks visibility of all users in the bar, it 
                allows users to traverse back and forth, maintaining a sense of 
                presence.<br /><br />

                As this version is greatly simplified while maintaining core 
                functionality, I chose to finalize the slide deck.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="design-system">
        <div className="wrapper">
          <div className="titles">
            <h3>The Design System</h3>
            <p>Blending classic nightlife with modern app design</p>
          </div>
          <div className="design-system-cards">
            <div className="design-system-card" style={{'marginRight': '40px'}}>
              <h4>Color</h4>
              <div className="contents">
                <div className="featured-color">
                  <div className="text">
                    <p className="eyebrow">PRIMARY BACKGROUND</p>
                    <p>Mainly used as a background color to convey a sense of night time.</p>
                  </div>
                  <div className="color-card">
                    <div className="color-charcoal" />
                    <div className="titles">
                      <p className="title">Charcoal</p>
                      <p className="subtitle">#151515</p>
                    </div>
                  </div>
                </div>
                <div className="featured-color">
                  <div className="text">
                    <p className="eyebrow">PRIMARY TEXT</p>
                    <p>Used for text, icons, and as a more subdued accent color.</p>
                  </div>
                  <div className="color-card">
                    <div className="color-white" />
                    <div className="titles">
                      <p className="title">White</p>
                      <p className="subtitle">#FFFFFF</p>
                    </div>
                  </div>
                </div>
                <div className="featured-color">
                  <div className="text">
                    <p className="eyebrow">PRIMARY ACCENT</p>
                    <p>Pink conveys romance, neon harkens to night life. Used sparingly.</p>
                  </div>
                  <div className="color-card">
                    <div className="color-hot-pink" />
                    <div className="titles">
                      <p className="title">Hot Pink</p>
                      <p className="subtitle">#FF007D</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="design-system-card" style={{'padding': '0px', 'marginRight': '40px'}}>
              <h4 style={{'padding': '0px 40px', 'paddingTop': '40px'}}>Typography</h4>
              <div className="typography-contents">
                <div className="headings">
                  <div className="heading-previews">
                    <div className="font-feature">
                      <div className="font-stack">
                        <p className="heading1">Heading 1</p>
                        <p className="font-description">Moret, 50pt</p>
                      </div>
                      <div className="font-stack">
                        <p className="heading2">Heading 2</p>
                        <p className="font-description">Moret, 30pt</p>
                      </div>
                      <div className="font-stack">
                        <p className="heading3">Heading 3</p>
                        <p className="font-description">Moret, 22pt</p>
                      </div>
                    </div>
                    <p className="eyebrow">HEADINGS</p>
                  </div>
                  <div className="heading-description">
                    <p className="title">Moret, Regular</p>
                    <p className="description">This digital-friendly serif font balances 
                    the antiquity of 1920s speakeasies with the playfulness of the 
                    dating app scene.</p>
                  </div>
                </div>
                <div className="body-text">
                  <div className="body-previews">
                    <p className="eyebrow">BODY TEXT</p>
                    <div className="font-feature">
                      <div className="font-stack">
                        <p className="body1">Body 1</p>
                        <p className="font-description">SF Pro Display, 18pt</p>
                      </div>
                      <div className="font-stack">
                        <p className="body2">Body 2</p>
                        <p className="font-description">SF Pro Display, 14pt</p>
                      </div>
                    </div>
                  </div>
                  <div className="body-description">
                    <p className="title">SF Pro Display, Regular</p>
                    <p className="description">To equalize the bold choice of Moret for 
                    headings, I opted for the neutral SF Pro for body text, Apple's 
                    successor to Helvetica Neue.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="design-system-card">
              <h4>Components</h4>
              <div className="contents">
                <div className="headers">
                  <img src={DesignSystemHeader1} alt="Bar Main Header"/>
                  <img src={DesignSystemHeader2} alt="Bar Chat Header"/>
                  <p><b>Headers</b> are uncluttered, navigable views whose call-to-actions 
                  are bold and comprehensible.</p>
                </div>
                <div className="messaging">
                  <img src={DesignSystemMessaging} alt="Messaging Preview"/>
                  <p><b>The Messaging Interface</b> appears throughout the app to drive 
                  home Speakeasy's communication-centric mission</p>
                  <p><b>Background blur</b> is a trend I have embraced after years of 
                  resistance. It allows for layering and depth unlike any other design 
                  pattern.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-tour">
        <div className="titles">
          <h3>The Final Design Tour</h3>
          <p>Follow the characters of White Lotus Season 2 
          through a demo of Speakeasy</p>
        </div>
        <div className="row-1">
          <div className="panel-1">
            <img src={FinalR1Image1} alt="Screenshot: Albie sees Portia"/>
            <img src={FinalR1Image2} alt="Screenshot: Albie approaches Portia"/>
          </div>
          <div className="panel-2">
            <img src={FinalR1Image3} alt="Screenshot: Portia sees Albie's approach"/>
          </div>
        </div>
        <div className="row-2">
          <div className="panel-1">
            <img src={FinalR2Image1} alt="Screenshot: Albie sees Portia's rejection"/>
            <img src={FinalR2Image2} alt="Screenshot: Albie goes back to the bar"/>
            <img src={FinalR2Image3} alt="Screenshot: Albie sees Lucia's approach"/>
          </div>
          <div className="panel-2">
            <img src={FinalR2Image4} alt="Screenshot: Lucia approaches Albie"/>
          </div>
        </div>
        <div className="row-3">
          <div className="panel-1">
            <img src={FinalR3Image1} alt="Screenshot: Beginning of chat"/>
            <img src={FinalR3Image2} alt="Screenshot: In-chat profile view"/>
            <img src={FinalR3Image3} alt="Screenshot: Timer runs out"/>
            <img src={FinalR3Image4} alt="Screenshot: Contacts sent"/>
          </div>
        </div>
      </div>
      <div className="reflections">
        <div className="text">
          <h3>Reflections and Future Insights</h3>
          <p>I started working on this project three years ago, but talked myself 
          out of it for numerous reasons. After reviving Speakeasy for my UX 
          portfolio, I became convinced that the idea might actually work, and 
          I'm willing to build it to see if I'm right.<br /><br /> 
          
          Assuming mass user acquisition (a huge assumption), I'm excited to find 
          out if the fast-paced, real-time environment is exhilarating or 
          exhausting for users. I'm also curious to see if users will make the 
          first move more or less frequently than they do with traditional dating 
          apps, as the stakes are much higher on Speakeasy. Lastly, I'm interested 
          to know how many chats will make it to the five minute mark, and whether 
          users think the time limit is too long or too short.</p>
        </div>
      </div>
      <Footer footerColor="var(--speakeasy-footer)"/>
    </div>
  );
};

export default Speakeasy;