import React, { useState, useEffect } from "react";
import './Trivio.css';
// Component Imports
import NavigationBar from '../../shared/NavigationBar/NavigationBar';
import Footer from '../../shared/Footer/Footer';

// Act One Image Imports
import TrivioCoverMockup from '../../photos/Trivio/TrivioCoverMockup.png';
import ActContentsIntroduction from '../../photos/Trivio/ActsContentsIntroduction.png';
import TrivioAtAGlance from '../../photos/Trivio/TrivioAtAGlance.png';
import WireframeGameplayMVPBoardInactive from '../../photos/Trivio/WireframeGameplayMVPBoardInactive.png';
import WireframeGameplayMVPBoardActive from '../../photos/Trivio/WireframeGameplayMVPBoardActive.png';
import WireframeGameplayMVPClueInactive from '../../photos/Trivio/WireframeGameplayMVPClueInactive.png';
import WireframeGameplayMVPClueActive from '../../photos/Trivio/WireframeGameplayMVPClueActive.png';
import WireframeGameplayMVPRevealedClueInactive from '../../photos/Trivio/WireframeGameplayMVPRevealedClueInactive.png';
import WireframeGameplayMVPRevealedClueActive from '../../photos/Trivio/WireframeGameplayMVPRevealedClueActive.png';
import Quizlet1 from '../../photos/Trivio/Quizlet1.png';
import Quizlet2 from '../../photos/Trivio/Quizlet2.png';
import Quizlet3 from '../../photos/Trivio/Quizlet3.png';
import TriviaMaker1 from '../../photos/Trivio/TriviaMaker1.png';
import TriviaMaker2 from '../../photos/Trivio/TriviaMaker2.png';
import TriviaMaker3 from '../../photos/Trivio/TriviaMaker3.png';
import JeopardyLabs1 from '../../photos/Trivio/JeopardyLabs1.png';
import JeopardyLabs2 from '../../photos/Trivio/JeopardyLabs2.png';
import JeopardyLabs3 from '../../photos/Trivio/JeopardyLabs3.png';
import QuizletLogo from '../../photos/Trivio/QuizletLogo.svg';
import TrivaMakerLogo from '../../photos/Trivio/TriviaMakerLogo.svg';
import JeopardyLabsLogo from '../../photos/Trivio/JeopardyLabsLogo.svg';
import TrivioLogo from '../../photos/Trivio/TrivioLogo.svg';
import ChevronLeft from '../../photos/Shared Icons/ChevronLeft.svg';
import ChevronRight from '../../photos/Shared Icons/ChevronRight.svg';
import LargeForwardSlash from '../../photos/Shared Icons/LargeForwardSlash.svg';
import TrivioTakeawaysHomepage from '../../photos/Trivio/TrivioTakeawaysHomepage.png';
import TrivioTakeawaysSetBuilder from '../../photos/Trivio/TrivioTakeawaysSetBuilder.png';
import MissTinaFullPortrait from '../../photos/Trivio/MissTinaFullPortrait.png';
import MissTinaThumbnailPortrait from '../../photos/Trivio/MissTinaThumbnailPortrait.png';
import MissTinaBioData from '../../photos/Trivio/MissTinaBioData.png';

// Act One Slideshow Image Imports
import ArrowBackButtonUnfocused from '../../photos/Shared Icons/ArrowBackButtonUnfocused.png';
import ArrowBackButtonFocused from '../../photos/Shared Icons/ArrowBackButtonFocused.png';
import ArrowForwardButtonUnfocused from '../../photos/Shared Icons/ArrowForwardButtonUnfocused.png';
import ArrowForwardButtonFocused from '../../photos/Shared Icons/ArrowForwardButtonFocused.png';
import ActOneSlideshowSlide1 from '../../photos/Trivio/Act1Slideshow/Act1Slide1.png';
import ActOneSlideshowSlide2 from '../../photos/Trivio/Act1Slideshow/Act1Slide2.png';
import ActOneSlideshowSlide3 from '../../photos/Trivio/Act1Slideshow/Act1Slide3.png';
import ActOneSlideshowSlide4 from '../../photos/Trivio/Act1Slideshow/Act1Slide4.png';
import ActOneSlideshowSlide5 from '../../photos/Trivio/Act1Slideshow/Act1Slide5.png';
import ActOneSlideshowSlide6 from '../../photos/Trivio/Act1Slideshow/Act1Slide6.png';
import ActOneSlideshowSlide7 from '../../photos/Trivio/Act1Slideshow/Act1Slide7.png';
import ActOneSlideshowSlide8 from '../../photos/Trivio/Act1Slideshow/Act1Slide8.png';
import ActOneSlideshowSlide9 from '../../photos/Trivio/Act1Slideshow/Act1Slide9.png';
import ActOneSlideshowSlide10 from '../../photos/Trivio/Act1Slideshow/Act1Slide10.png';
import ActOneSlideshowSlide11 from '../../photos/Trivio/Act1Slideshow/Act1Slide11.png';
import ActOneSlideshowSlide12 from '../../photos/Trivio/Act1Slideshow/Act1Slide12.png';

// Continuation of Act One Imports
import SetMakeup51 from '../../photos/Trivio/SetMakeup51.svg';
import SetMakeup26 from '../../photos/Trivio/SetMakeup26.svg';
import SetMakeup9 from '../../photos/Trivio/SetMakeup9.svg';
import SetMakeup8 from '../../photos/Trivio/SetMakeup8.svg';
import SetMakeup6 from '../../photos/Trivio/SetMakeup6.svg';
import KahootAuditGood from '../../photos/Trivio/KahootAuditGood.png';
import KahootAuditBad from '../../photos/Trivio/KahootAuditBad.png';
import KahootLogo from '../../photos/Trivio/KahootLogo.png';
import TrivioFreePlan from '../../photos/Trivio/TrivioFreePlan.png';
import TrivioPlusPlan from '../../photos/Trivio/TrivioPlusPlan.png';
import DesktopWireframe from '../../photos/Trivio/DesktopWireframe.png';
import DeselectedCircleOption from '../../photos/Shared Icons/DeselectedCircleOption.svg';
import SelectedCircleOption from '../../photos/Shared Icons/SelectedCircleOption.svg';

// Act Two Buzzer Showcase Imports
import GameCodeEntry from '../../photos/Trivio/Act2BuzzerShowcase/GameCodeEntry.png';
import ActiveBuzzer from '../../photos/Trivio/Act2BuzzerShowcase/ActiveBuzzer.png';
import WinningBuzzer from '../../photos/Trivio/Act2BuzzerShowcase/WinningBuzzer.png';
import TeamWinBuzzer from '../../photos/Trivio/Act2BuzzerShowcase/TeamWinBuzzer.png';
import WagerPrompt from '../../photos/Trivio/Act2BuzzerShowcase/WagerPrompt.png';
import FinalResponseTeamPoll from '../../photos/Trivio/Act2BuzzerShowcase/FinalResponseTeamPoll.png';

// Act Two Slideshow Image Imports
import DesktopSlide1 from '../../photos/Trivio/Act2Slideshow/DesktopSlide1.png';
import DesktopSlide2 from '../../photos/Trivio/Act2Slideshow/DesktopSlide2.png';
import DesktopSlide3 from '../../photos/Trivio/Act2Slideshow/DesktopSlide3.png';
import DesktopSlide4 from '../../photos/Trivio/Act2Slideshow/DesktopSlide4.png';
import DesktopSlide5 from '../../photos/Trivio/Act2Slideshow/DesktopSlide5.png';
import DesktopSlide6 from '../../photos/Trivio/Act2Slideshow/DesktopSlide6.png';
import DesktopSlide7 from '../../photos/Trivio/Act2Slideshow/DesktopSlide7.png';
import DesktopSlide8 from '../../photos/Trivio/Act2Slideshow/DesktopSlide8.png';
import DesktopSlide9 from '../../photos/Trivio/Act2Slideshow/DesktopSlide9.png';
import DesktopSlide10 from '../../photos/Trivio/Act2Slideshow/DesktopSlide10.png';

import MobileSlide1 from '../../photos/Trivio/Act2Slideshow/MobileSlide1.png';
import MobileSlide2 from '../../photos/Trivio/Act2Slideshow/MobileSlide2.png';
import MobileSlide3 from '../../photos/Trivio/Act2Slideshow/MobileSlide3.png';
import MobileSlide4 from '../../photos/Trivio/Act2Slideshow/MobileSlide4.png';
import MobileSlide5 from '../../photos/Trivio/Act2Slideshow/MobileSlide5.png';
import MobileSlide6 from '../../photos/Trivio/Act2Slideshow/MobileSlide6.png';
import MobileSlide7 from '../../photos/Trivio/Act2Slideshow/MobileSlide7.png';
import MobileSlide8 from '../../photos/Trivio/Act2Slideshow/MobileSlide8.png';
import MobileSlide9 from '../../photos/Trivio/Act2Slideshow/MobileSlide9.png';
import MobileSlide10 from '../../photos/Trivio/Act2Slideshow/MobileSlide10.png';

const Trivio = () => {
  // SETUP: Preload all necessary image URLs
  const preloadImages = async (imageUrls) => {
    const loadSingleImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = () => reject(new Error(`Failed to load ${url}`));
      });
    };
  
    const loadPromises = imageUrls.map(loadSingleImage);
    return Promise.all(loadPromises);
  };

  // Act 1, Chapter 1 Wireframes
  const [activeClassWfMVP, setActiveClassWfMVP] = useState("");
  const [activeWireframeMVP, setActiveWireframeMVP] = useState("Board");
  // For fading animations for Act 1 Slideshow
  useEffect(() => {
    setActiveClassWfMVP('annotation-fade-exit-active');
    const timer1 = setTimeout(() => {
      setActiveClassWfMVP('annotation-fade-enter');
    }, 30);
    const timer2 = setTimeout(() => {
      setActiveClassWfMVP('annotation-fade-enter-active');
    }, 100);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [activeWireframeMVP]);

  // Act 1, Chapter 2 Audit
  const [auditCompetitorIndex, setAuditCompetitorIndex] = useState(0);
  const [auditSubpageIndex, setAuditSubpageIndex] = useState(0);
  const competitorSubpages = [
    ["Homepage", "Set Preview", "Set Builder"],
    ["Set Builder I", "Set Builder II", "Set Builder III"],
    ["Set Builder I", "Set Builder II", "Set Builder III"]
  ]
  const competitors = ["Quizlet (App)", "TriviaMaker", "JeopardyLabs"];
  const [trivioFeatureToDisplay, setTrivioFeatureToDisplay] = useState("Homepage");
  
  // Act 1, Chapter 3 Slideshow
  const act1SlideTitles = [
    "Homepage: Zero State", "Set Builder I: Essentials", "Set Builder II: The Board",
    "Set Builder III: The Clue", "Set Builder IV: WVC", "Set Builder V: Final Clue",
    "Homepage: Populated", "Set Preview Page", "Gameplay I: The Board", 
    "Gameplay II: The Clue", "Gameplay III: The Reveal", "Gameplay IV: The Podium"
  ]
  const act1SlideshowImages = [
    ActOneSlideshowSlide1, ActOneSlideshowSlide2, ActOneSlideshowSlide3, 
    ActOneSlideshowSlide4, ActOneSlideshowSlide5, ActOneSlideshowSlide6,
    ActOneSlideshowSlide7, ActOneSlideshowSlide8, ActOneSlideshowSlide9,
    ActOneSlideshowSlide10, ActOneSlideshowSlide11, ActOneSlideshowSlide12,
  ]
  const [act1NewSlideIndex, setAct1NewSlideIndex] = useState(0);
  const [act1SlideIndex, setAct1SlideIndex] = useState(0);
  const [activeClass1, setActiveClass1] = useState('');
  const [activeAnnotationClass1, setActiveAnnotationClass1] = useState('');
  // For fading animations for Act 1 Slideshow
  useEffect(() => {
    setActiveClass1('image-fade-exit-active');
    setActiveAnnotationClass1('annotation-fade-enter')
    const timer1 = setTimeout(() => {
      setAct1SlideIndex(act1NewSlideIndex);
      setActiveClass1('image-fade-enter');
    }, 30);
    const timer2 = setTimeout(() => {
      setActiveClass1('image-fade-enter-active');
      setActiveAnnotationClass1('annotation-fade-enter-active')
    }, 100);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [act1SlideIndex, act1NewSlideIndex]);

  // Act 1, Chapter 3 By The Numbers
  const [hoveredMakeupElement, setHoveredMakeupElement] = useState(SetMakeup51);
  
  // Act 2, Chapter 1 Kahoot Audit
  const [auditDisplayOption, setAuditDisplayOption] = useState("Business");
  
  // Act 2, Chapter 2 Designing the Buzzer
  const [preBuzzerDisplay, setPreBuzzerDisplay] = useState("Game Code Entry");
  const [buzzerDisplay, setBuzzerDisplay] = useState("Game Code Entry");
  const [activeBuzzerClass, setActiveBuzzerClass] = useState('');
  const [activeAntBuzzClass, setActiveAntBuzzClass] = useState('');
  const buzzerDisplaySourceMap = {
    "Game Code Entry": GameCodeEntry,
    "Active Buzzer": ActiveBuzzer,
    "Winning Buzzer": WinningBuzzer,
    "Team Win Buzzer": TeamWinBuzzer,
    "Wager Prompt": WagerPrompt,
    "Final Response Team Poll": FinalResponseTeamPoll,
  }
  // For fading animations in Buzzer Design
  useEffect(() => {
    setActiveBuzzerClass('image-fade-exit-active');
    setActiveAntBuzzClass('annotation-fade-enter');
    const timer1 = setTimeout(() => {
      setBuzzerDisplay(preBuzzerDisplay);
      setActiveBuzzerClass('image-fade-enter');
    }, 50);
    const timer2 = setTimeout(() => {
      setActiveBuzzerClass('image-fade-enter-active');
      setActiveAntBuzzClass('annotation-fade-enter-active')
    }, 100);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [buzzerDisplay, preBuzzerDisplay]);

  // Act 2, Chapter 2 Slideshow
  const [act2NewSlideIndex, setAct2NewSlideIndex] = useState(0);
  const [act2SlideIndex, setAct2SlideIndex] = useState(0);
  const [activeClass2, setActiveClass2] = useState('');
  const [activeAnnotationClass2, setActiveAnnotationClass2] = useState('');
  // Fade animations for Act 2 Slideshow
  useEffect(() => {
    setActiveClass2('image-fade-exit-active');
    setActiveAnnotationClass2('annotation-fade-enter')
    const timer1 = setTimeout(() => {
      setAct2SlideIndex(act2NewSlideIndex);
      setActiveClass2('image-fade-enter');
    }, 30);
    const timer2 = setTimeout(() => {
      setActiveClass2('image-fade-enter-active');
      setActiveAnnotationClass2('annotation-fade-enter-active')
    }, 100);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [act2SlideIndex, act2NewSlideIndex]);
  const act2DesktopSlides = [
    DesktopSlide1, DesktopSlide2, DesktopSlide3, DesktopSlide4, DesktopSlide5, 
    DesktopSlide6, DesktopSlide7, DesktopSlide8, DesktopSlide9, DesktopSlide10 
  ]
  const act2MobileSlides = [
    MobileSlide1, MobileSlide2, MobileSlide3, MobileSlide4, MobileSlide5, 
    MobileSlide6, MobileSlide7, MobileSlide8, MobileSlide9, MobileSlide10 
  ]
  const act2SlideTitles = [
    "Waiting for the Game to Start", "Brian Picks a Clue", "The Clue is Presented",
    "Lauren Wins the Buzzer Race", "Lauren Answers Incorrectly",
    "David Buzzes Again", "David Wins the Buzzer Race", "600 Points for Team 1",
    "Response Reveal", "Back to the Board"
  ]

  return (
    <div className="trivio">
      <div className="trivio-cover">
        <NavigationBar backgroundColor="--trivio-bg"/>
        <div className="trivio-cover-content">
          <div className="title-subtitle">
            <h2>Trivio, Jeopardy App</h2>
            <span style={{ opacity: 0.6 }}>Original mobile and web app with </span>
            <b style={{ opacity: 1 }}>5,000 iOS users </b>
            <span style={{ opacity: 0.6 }}>and counting. Story told in </span>
            <b style={{ opacity: 1 }}>two acts.</b>
          </div>
          <div className="mockup-container">
            <img src={TrivioCoverMockup} alt="Trivio Mockup" />
          </div>
          <div className="cover-details">
            <div className="details-entry">
              <p className="p1">TIMELINE</p>
              <p className="p2">Feb 2021 - Present</p>
            </div>
            <div className="details-entry">
              <p className="p1">ROLE</p>
              <p className="p2">Sole designer, Sole developer</p>
            </div>
            <div className="details-entry">
              <p className="p1">TOOLS</p>
              <p className="p2">Sketch, Xcode, VSCode</p>
            </div>
            <div className="details-entry">
              <p className="p1">APP STORE URL</p>
              <p className="p2">apps.apple.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="trivio-introduction-wrapper">
        <div className="trivio-introduction">
          <h2>Introduction</h2>
          <div className="introduction-contents">
            <p> Trivio is an app that allows users to <b>build and play custom Jeopardy 
            sets.</b> The idea was unofficially born when my adolescent brain assigned itself the 
            role of trivia showrunner at summer camp. Officially, Trivio was built 
            almost a decade after my summer camp days, but the small part of my 
            identity that drove me to hold trivia sessions as a kid stayed with me into 
            my junior year of college, where I built Trivio version 1.0 the night before 
            I volunteered to host a Zoom Jeopardy social. That was in February 2021, I 
            released it on the App Store the following May, and since then, the app has 
            undergone several updates and is enjoying a steadily growing user base. </p>
            <img src={ActContentsIntroduction} alt="Act One, Act Two Graphic" />
            <p> In this two-act case study, I will be covering the evolution of this app 
            from the days when it only existed on my iPhone to its current state as the top 
            result under “Jeopardy Maker” in the iOS App Store. In <b>act one</b>, I will 
            cover everything from Trivio's conception to the present. In <b>act two</b>, I  
            will cover my future plans for Trivio, which consist of a monetization strategy 
            and a redesign for web. </p>
          </div>
        </div>
      </div>
      <div className="at-a-glance">
        <div className="wrapper">
          <div className="titles">
            <div className="titles-wrapper">
              <h4>Trivio's Mobile & Web Apps</h4>
              <p>At a Glance</p>
            </div>
          </div>
        </div>
        <div className="bottom-gradient"/>
        <img src={TrivioAtAGlance} alt="Collection of Trivio screenshots"/>
      </div>
      <div className="act1-chapter1">
        <div className="act1-title-subtitle">
          <h2>Act One</h2>
          <p>How Trivio Became the App it is Today</p>
        </div>
        <div className="chapter-contents-1">
          <h5>ACT 1, CHAPTER 1</h5>
          <h6>Designing the MVP</h6><br/><br/>
          <p>I first built Trivio because I couldn't find a Jeopardy app I wanted to 
          use, and I had enough experience developing mobile apps to feel confident 
          that I could make a better solution myself.</p><br/>
          <p>Because I prefer designing in order of importance, <b>gameplay</b> was top of 
          mind when planning the MVP. My goal was to create an experience that would 
          feel instantly recognizable and comfortable to those who are already 
          accustomed to the format of Jeopardy. In order for that to happen, there 
          were two key visual elements that had to remain from the show: <b>the board
          and the clue.</b> The following gallery shows how I designed to maximize 
          familiarity and thus usability.</p>
        </div>
      </div>
      <div className="act1-chapter1-wireframe-gallery">
        <div className="gallery-content-wrapper">
          <div className="title-subtitle">
            <h4>Wireframe Gallery: Gameplay MVP</h4>
            <p>Select each frame to learn more</p>
          </div>
          <div className="interactive-wireframes">
            <img 
              onClick={() => setActiveWireframeMVP("Board")}
              className={activeWireframeMVP === "Board" ? "" : "unfocused"}
              src={activeWireframeMVP === "Board" ? WireframeGameplayMVPBoardActive : WireframeGameplayMVPBoardInactive} 
              alt="Wireframe of the Trivio board"   
            />
            {activeWireframeMVP === "Board" &&
              <div className={`wireframe-annotations ${activeClassWfMVP}`}>
                <h2>THE BOARD</h2>
                <p><b>The Progress Bar</b> prevents users from prematurely believing 
                their round has finished, as most of the board extends beyond 
                the screen.</p>
                <p style={{'marginTop': '68px'}}><b>The Board</b> scrolls horizontally, allowing users full control 
                and visibility. This design takes advantage of off-screen real estate 
                to make the board feel spacious and life-sized.</p>
                <p style={{'marginTop': '159px'}}><b>Each Numbered Cell</b> is an interactive button which presents 
                the clues of its corresponding category and point value. After clues 
                are played, their previews are replaced with an empty cell.</p>
              </div>
            }
            <img 
              onClick={() => setActiveWireframeMVP("Clue")}
              className={activeWireframeMVP === "Clue" ? "" : "unfocused"}
              src={activeWireframeMVP === "Clue" ? WireframeGameplayMVPClueActive : WireframeGameplayMVPClueInactive} 
              alt="Wireframe of a Trivio clue" 
            />
            {activeWireframeMVP === "Clue" &&
              <div className={`wireframe-annotations ${activeClassWfMVP}`}>
                <h2>THE CLUE</h2>
                <p style={{'marginTop': '40px'}}><b>The Contestant</b> outlined in white is the contestant who 
                “has the board,” aka the last player who answered a clue correctly.</p>
                <p style={{'marginTop': '50px'}}><b>When The Narration</b> ends, the 10 second countdown timer
                above it is triggered. If users prefer not to hear the narration, they can disable 
                it by tapping on the speaker icon.</p>
                <p style={{'marginTop': '32px'}}><b>The Clue</b> is set in ITC Korinna, instantly familiar 
                to fans of the game show.</p>
                <p style={{'marginTop': '105px'}}><b>Users Show the Response</b> after contestants have 
                answered the clue. This presents both the response and the grading panel.</p>
              </div>
            }
            <img 
              onClick={() => setActiveWireframeMVP("Revealed Clue")}
              className={activeWireframeMVP === "Revealed Clue" ? "" : "unfocused"}
              src={activeWireframeMVP === "Revealed Clue" ? WireframeGameplayMVPRevealedClueActive : WireframeGameplayMVPRevealedClueInactive} 
              alt="Wireframe of a revealed Trivio clue"   
            />
            {activeWireframeMVP === "Revealed Clue" &&
              <div className={`wireframe-annotations ${activeClassWfMVP}`}>
                <h2>THE REVEALED RESPONSE</h2>
                <p style={{'marginTop': '48px'}}><b>The Scoreboard</b> updates according to the grading panel below. 
                Unlike other Jeopardy apps which limit users to adding and subtracting 
                the given points from their score, Trivio handles the adding and 
                subtracting for users.</p>
                <p style={{'marginTop': '122px'}}><b>When The Response</b> is showing, the clue's opacity is decreased 
                to draw more attention to the response.</p>
                <p style={{'marginTop': '61px'}}><b>The Grading Panel</b> reflects the scoreboard 
                in its formatting. All of the arithmetic involved with adding and 
                subtracting scores based on correct and incorrect responses is handled by the app.</p>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="act1-chapter2">
        <div className="chapter-content-wrapper">
          <div className="title-subtitle">
            <h5>ACT 1, CHAPTER 2</h5>
            <h6>Getting Trivio to Production</h6>
          </div>
          <div className="chapter-paragraphs">
            <p>There were two main systems I had to design on top of the MVP 
            in order to release the app: a <b>homepage,</b> where users could view 
            the sets they'd built and discover new sets from the community, 
            and a <b>set builder interface,</b> where users could create their sets.</p><br/>
            <p>Before designing any of these elements, I visited competitive 
            apps to see how they tackled similar features. In the following 
            competitive audit, I feature three of the apps that I drew inspiration 
            from: <b>Quizlet,</b> a platform which also relies on its community for 
            content, <b>TriviaMaker,</b> the only iOS Jeopardy app available prior to 
            Trivio, and <b>JeopardyLabs,</b> a popular Jeopardy site with over 5 million 
            sets on its platform.</p>
          </div>
        </div>
      </div>
      <div className="act1-chapter2-audit">
        <div className="audit-wrapper">
          <div className="title-subtitle">
            <h1>Competitive Audit</h1>
            <p>Comparing relevant features from Trivio's competitors</p>
          </div>
          <div className="audit-contents">
            {(auditCompetitorIndex === 0 || auditCompetitorIndex === 1) &&
              <div className="mobile-screenshots">
                <img 
                  onClick={() => setAuditSubpageIndex(0)}
                  className={auditSubpageIndex === 0 ? "focused" : "unfocused"}
                  src={auditCompetitorIndex === 0 ? Quizlet1 : TriviaMaker1}
                  alt="Screenshot 1 of 3" />
                <img 
                  onClick={() => setAuditSubpageIndex(1)}
                  className={auditSubpageIndex === 1 ? "focused" : "unfocused"}
                  src={auditCompetitorIndex === 0 ? Quizlet2 : TriviaMaker2}
                  alt="Screenshot 2 of 3" />
                <img 
                  onClick={() => setAuditSubpageIndex(2)}
                  className={auditSubpageIndex === 2 ? "focused" : "unfocused"}
                  src={auditCompetitorIndex === 0 ? Quizlet3 : TriviaMaker3}
                  alt="Screenshot 3 of 3" />
              </div>
            }
            {auditCompetitorIndex === 2 &&
              <div className="desktop-screenshots">
                <div className="thumbnails">
                  <img
                    onClick={() => setAuditSubpageIndex(0)}
                    className={auditSubpageIndex === 0 ? "focused" : "unfocused"}
                    src={JeopardyLabs1} 
                    alt="Empty set builder template for JeopardyLabs"   
                  />
                  <img 
                    onClick={() => setAuditSubpageIndex(1)}
                    className={auditSubpageIndex === 1 ? "focused" : "unfocused"}
                    src={JeopardyLabs2} 
                    alt="JeopardyLabs Clue and response text form" />
                  <img 
                    onClick={() => setAuditSubpageIndex(2)}
                    className={auditSubpageIndex === 2 ? "focused" : "unfocused"}
                    src={JeopardyLabs3} 
                    alt="Partially filled JeopardyLabs board" />
                </div>
                <div className="main-slide">
                  <img 
                    src={auditSubpageIndex === 0 ? JeopardyLabs1 : (auditSubpageIndex === 1 ? JeopardyLabs2 : JeopardyLabs3)} 
                    alt ="Main slide content for JeopardyLabs" />
                </div>
              </div>
            }
            <div className="audit-text-wrapper">
              <div className="audit-competitor-heading">
                <div className="audit-competitor-info">
                  <img 
                    src={auditCompetitorIndex === 0 ? QuizletLogo : (auditCompetitorIndex === 1 ? TrivaMakerLogo : JeopardyLabsLogo)} 
                    alt="Quizlet logo" />
                  <h2>{competitors[auditCompetitorIndex].toUpperCase()}</h2>
                  <img
                    onClick={() => {
                      setAuditSubpageIndex(0);
                      setAuditCompetitorIndex(auditCompetitorIndex - (auditCompetitorIndex > 0) ? 1 : 0)}
                    }
                    className={auditCompetitorIndex === 0 ? "disabled-chevron" : "enabled-chevron"}
                    src={ChevronLeft} alt="Previous Button" />
                  <img 
                    onClick={() => {
                      setAuditSubpageIndex(0);
                      setAuditCompetitorIndex(auditCompetitorIndex + ((auditCompetitorIndex < 2) ? 1 : 0))}
                    } 
                    className={auditCompetitorIndex === 2 ? "disabled-chevron" : "enabled-chevron"}
                    src={ChevronRight}
                    alt="Next Button" />
                </div>
                <h1>{competitorSubpages[auditCompetitorIndex][auditSubpageIndex]}</h1>
              </div>
              {auditCompetitorIndex === 0 &&
                <div className="quizlet-paragraphs">
                  {auditSubpageIndex === 0 &&
                    <div className="audit-info-paragraphs">
                      <p>Most prominent on Quizlet's homepage is the <b>search bar,</b> which is the only 
                      all-white element on the screen (rightfully so). I imagine that users 
                      discover the vast majority of Quizlet sets through the search bar, 
                      considering the difficulty involved for Quizlet to accurately predict 
                      exactly which flashcard sets a user might study next.</p><br/>
                      <p>The rest of the homepage is dedicated to the sets that a user 
                      has previously played, and there are two additional rows of 
                      recommendations based on previous usage.</p>
                    </div>
                  }
                  {auditSubpageIndex === 1 &&
                    <div className="audit-info-paragraphs">
                      <p>Quizlet and Trivio are similar in that tapping on the homepage's 
                      set thumbnail does not bring you directly to either the full-page flashcards 
                      view (as in Quizlet's case) or the Jeopardy game (as in Trivio's case). </p>
                      <p>For Quizlet, I speculate that the intermediate set preview page 
                      exists so that users can see the <b>entire set at a glance</b>, and also 
                      so Quizlet can <b>advertise their other features</b> like learn, 
                      test, and match.</p><br/>
                      <p>Trivio needs a set preview page because users need to add contestants 
                      to their round of Jeopardy. On top of adding contestants, users will see 
                      more detailed information about the set which will inform them whether 
                      or not they'd like to play it, just as Quizlet does.</p>
                    </div>
                  }
                  {auditSubpageIndex === 2 &&
                    <div className="audit-info-paragraphs">
                      <p>Gameshow components aside, building a flashcards set and building a 
                      Jeopardy set are <b>fairly similar:</b> both are comprised of individual 
                      questions, and each of those questions has a term and definition. 
                      Though building Jeopardy sets is much more involved, Trivio can 
                      take inspiration from the <b>simplicity</b> of the flashcards-
                      building process.</p><br/>
                      <p>As for the UI, Quizlet has opted to keep all necessary elements 
                      on a single page. To accomplish this, they've added a bar on top of 
                      the keyboard, packed with many of the controls a user might want quick 
                      access to.</p><br/>
                      <p>The takeaway for Trivio is to try and keep set building as 
                      fundamental as possible, sparing any bells and whistles that are 
                      not strictly necessary.</p>
                    </div>
                  }
                </div>
              }
              {auditCompetitorIndex === 1 &&
                <div className="trivia-maker-paragraphs">
                  {auditSubpageIndex === 0 &&
                    <div className="audit-info-paragraphs">
                      <p>As I mentioned earlier, I created Trivio because TriviaMaker did not meet my 
                      needs. Thus, I audited TriviaMaker more as a guide on <b>what not to do</b>, rather 
                      than as an example to follow.</p><br/>
                      <p>In the first panel on the left, the category view doesn't give users a sense
                      of how many clues are finished, and any category title longer than six letters 
                      is cut off.</p><br/>
                      <p>The ill-placed tab bar at the bottom of the screen is supposed to give 
                      users a sense of progress and orientation, but its design makes it seem like 
                      it would be used for app navigation. To make matters worse, 90% of the set 
                      building experience happens in the first tab, which weakens the purpose 
                      of having it in the first place.</p>
                    </div>
                  }
                  {auditSubpageIndex === 1 &&
                    <div className="audit-info-paragraphs">
                      <p>This screen, which we will call “Category Details,” is presented when a 
                      user either adds a new category or edits an existing one. Contrary to 
                      intuition, the only way to navigate back to the previous page is to tap 
                      “Save” in the top-right corner. If a user tries to tap the back icon, 
                      the app will backtrack to the homepage, and all of the user's progress 
                      will be lost. </p><br/>
                      <p>This level of fragility in the app's architecture can induce anxiety 
                      in a user, as one small mistake can nullify an hour that the user has 
                      spent building the app.</p><br/>
                      <p>This category's questions allow a user to edit them, but there are 
                      no previews of the text, so a user has to painstakingly tap on every 
                      question just to see what they wrote.</p>
                    </div>
                  }
                  {auditSubpageIndex === 2 &&
                    <div className="audit-info-paragraphs">
                      <p>The question and response page is more functional than the previous two, 
                      but still not well-designed. To start, there are no indications of which 
                      category this question belongs to, and within that category, there are no 
                      indications of the point value. This missing information is critical, as 
                      Jeopardy sets are written in increasing levels of difficulty as players 
                      progress through a category. </p><br/><br/>
                      <p>The process of building a set in TriviaMaker mostly consists of 
                      aggressively navigating between the three screens I've presented here, 
                      which can be fatiguing for users. To add to the fatigue, there are no 
                      visual indicators throughout the process as to how many clues one has 
                      already made and how many are left to go. </p>
                    </div>
                  }
                </div>
              }
              {auditCompetitorIndex === 2 &&
                <div className="jeopardy-labs-paragraphs">
                  {auditSubpageIndex === 0 &&
                    <div className="audit-info-paragraphs">
                      <p>Perhaps most applicable of the three competitors in this audit is JeopardyLabs, 
                      the <b>incumbent Jeopardy app</b> which has been wildly popular since 2008 
                      when it was first released.</p><br/>
                      <p>JeopardyLabs sets the bar for <b>simplicity</b> in an app; even though its UI maturity 
                      is lacking, its UX and product design makes the site <b>instantly usable.</b></p><br/>
                      <p>Here, in the empty set interface, the user is faced with multiple prompts which 
                      are straightforward and not overwhelming, despite there being a full page of 
                      interactive content. Clicking on each view does exactly what a user might 
                      anticipate.</p>
                    </div>
                  }
                  {auditSubpageIndex === 1 &&
                    <div className="audit-info-paragraphs">
                      <p>As with the other competitors that I've audited here, the content prompts are 
                      two simple text boxes with a very high level of customizability.</p><br/>
                      <p>The agnosticism of JeopardyLabs' design (raw HTML with barely any CSS) 
                      makes it very comfortable for any technologically literate person to use.</p><br/>
                      <p>Trivio's set builder UI will aim to be more visually pleasing, but my goal is 
                      for the interface to be just as intuitive as JeopardyLabs for new users.</p>
                    </div>
                  }
                  {auditSubpageIndex === 2 &&
                    <div className="audit-info-paragraphs">
                      <p>JeopardyLabs' board makes it very easy to see the clue and response
                      at a glance. Additionally, unlike TriviaMaker, there are plenty of 
                      visual indications that the user is making great progress on filling 
                      out their set.</p><br/>
                      <p>One minor critique of this interface is that when building a 
                      Jeopardy set, the few-word responses tell me much more about each question 
                      than do the long-form clues. This makes it slightly inconvenient 
                      that one must hover over a cell to see the response on the back. </p>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="act1-chapter2-takeaways">
        <div className="takeaways-wrapper">
          <div className="takeaways-text">  
            <h4>Takeaways from the Audit</h4>
            <div className="takeaways-text-content">
              <div className="trivio-branding-info">
                <img src={TrivioLogo} alt="Trivio logo" />
                <h2>TRIVIO</h2>
              </div>
              <div className="trivio-page-selector">
                <h2
                  onClick={() => setTrivioFeatureToDisplay("Homepage")} 
                  className={trivioFeatureToDisplay === "Homepage" ? "focused" : "unfocused"}
                >Homepage</h2>
                <img src={LargeForwardSlash} alt="/" />
                <h2
                  onClick={() => setTrivioFeatureToDisplay("Set Builder")} 
                  className={trivioFeatureToDisplay === "Set Builder" ? "focused" : "unfocused"}
                >Set Builder</h2>
              </div>
              {trivioFeatureToDisplay === "Homepage" &&
                <p>The final components in Trivio's homepage were largely inspired by 
                Quizlet. Like Quizlet, I include a search prompt, sets that the user 
                has made, and several rows of recommended sets based on a user's past 
                activity. Unlike Quizlet, there is no tab bar; elements from Quizlet's 
                tab bar like the “add” or “profile” buttons are easily accessible from 
                elsewhere on the home page.</p>
              }
              {trivioFeatureToDisplay === "Set Builder" &&
                <p>I've implemented a mosaic of competitive features in Trivio's
                set builder interface. Modeling after JeopardyLabs, I've made sure
                that the set builder's UI feels like one page, not many disjointed 
                pages like seen in TriviaMaker's UI. Quizlet teaches a similar 
                lesson in avoiding complexity, even for seemingly complex tasks.</p>
              }
            </div>
          </div>
          <img 
            onClick={() => setTrivioFeatureToDisplay("Homepage")}
            style={{'translate': '150px 0px', 'zIndex': trivioFeatureToDisplay === "Homepage" ? 1 : 0}}
            className={trivioFeatureToDisplay === "Homepage" ? "list-image-focused hp" : "list-image-unfocused"}
            src={TrivioTakeawaysHomepage}
            alt="List of Trivio's homepage components" />
          <img 
            onClick={() => setTrivioFeatureToDisplay("Set Builder")} 
            style={{'zIndex': trivioFeatureToDisplay === "Set Builder" ? 1 : 0}}
            className={trivioFeatureToDisplay === "Set Builder" ? "list-image-focused sb" : "list-image-unfocused"}
            src={TrivioTakeawaysSetBuilder} 
            alt="List of Trivio's set builder components" />
        </div>
      </div>
      <div className="act1-chapter3-section1">
        <div className="miss-tina-visuals">
          <img style={{'paddingRight': '15px'}} src={MissTinaFullPortrait} alt="Miss Tina's Portrait" />
          <img className="shadowed" src={MissTinaBioData} alt="Miss Tina's Basic Info" />
        </div>
        <div className="section1-text-wrapper">
          <div className="section1-text-headings">
            <h5>ACT 1, CHAPTER 3</h5>
            <h6>A Current User's Journey</h6>
          </div>
          <div className="section1-paragraphs">
            <p>Many <b>teachers</b> have used Trivio to create educational sets for their 
            students. Here, we will focus on one teacher in particular, <b>Miss Tina. </b> 
            Even though Miss Tina is fictitious, her profile is inspired by numerous 
            real users of Trivio.</p><br/>
            <p>The slideshow below guides you through each of Trivio's screens in 
            the order that Miss Tina (or any new Trivio user) might visit them. The 
            annotations on each slide serve doubly to identify major UI features 
            and also to highlight what Miss Tina might be thinking as she progresses 
            through the app.</p>
          </div>
        </div>
      </div>
      <div className="act1-chapter3-slideshow">
        <div className="slideshow-content-wrapper">
          <div className="slideshow-titles">
            <h4>User Journey Slideshow</h4>
            <p>Use the arrows below to navigate through the deck</p>
          </div>
          <div className="slideshow-contents">
            <Act1SlideshowLHAnnotations act1SlideIndex={act1SlideIndex} activeClass={activeAnnotationClass1} />
            <div className="current-slide-mockup">
              <img 
                className={activeClass1}
                src={act1SlideshowImages[act1SlideIndex]} 
                alt={"Slide "+ act1SlideshowImages[act1SlideIndex] + " of 10"}/>
              {act1SlideIndex < act1SlideshowImages.length - 1 &&
                <img
                  style={{'display': 'none'}}
                  src={act1SlideshowImages[act1SlideIndex + 1]} 
                  alt={"Slide "+ act1SlideshowImages[act1SlideIndex + 1] + " of 10"}/>
              }
            </div>
            <Act1SlideshowRHAnnotations act1SlideIndex={act1SlideIndex} activeClass={activeAnnotationClass1} />
          </div>
        </div>
        <div className="slideshow-control-panel">
          <div 
            style={act1SlideIndex === 0 ? {'pointerEvents': 'none', 'opacity': 0.4, 'user-select': 'none'} : {}}
            onClick={() => act1SlideIndex !== 0 && setAct1NewSlideIndex((act1NewSlideIndex) => act1NewSlideIndex - 1)}
            className="previous-arrow">
            {(act1SlideIndex !== 0 && act1SlideIndex !== 11) &&
              <div className="arrow-labels">
                <p className="p1">Previous</p>
                <p><b>{act1SlideTitles[act1SlideIndex - 1]}</b></p>
              </div>
            }
            <div 
              className={"stacked-arrow-buttons"}>
              <img src={ArrowBackButtonUnfocused} alt="Back Arrow Button (disabled)"/>
              <img src={ArrowBackButtonFocused} alt="Back Arrow Button (enabled)"/>
            </div>
          </div>
          <div className="current-slide-labels">
            <p style={{'opacity': 0.6}}>Slide {act1SlideIndex + 1}/12</p>
            <p style={{'marginTop': '5px'}}><b>{act1SlideTitles[act1SlideIndex]}</b></p>
          </div>
          <div 
            style={act1SlideIndex === 11 ? {'pointerEvents': 'none', 'opacity': 0.4, 'user-select': 'none'} : {}}
            onClick={() => act1SlideIndex !== 11 && setAct1NewSlideIndex((act1NewSlideIndex) => act1NewSlideIndex + 1)}
            className="next-arrow">
            <div 
              className="stacked-arrow-buttons">
              <img src={ArrowForwardButtonUnfocused} alt="Forward Arrow Button (disabled)"/>
              <img src={ArrowForwardButtonFocused} alt="Forward Arrow Button (enabled)"/>
            </div>
            {(act1SlideIndex !== 0 && act1SlideIndex !== 11) &&
              <div className="arrow-labels">
                <p className="p1">Next</p>
                <p><b>{act1SlideTitles[act1SlideIndex + 1]}</b></p>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="act1-chapter3-the-numbers">
        <div className="title-subtitle">
          <h4>Trivio Thus Far, by the Numbers</h4>
          <p>Aggregated data from May 2021-August 2023</p>
        </div>
        <div className="primary-metrics">
          <div className="metric">
            <div style={{'display': 'flex'}} className="title">
              <h1>9.5k</h1>
              <p className="p1">Downloads</p>
            </div>
            <p>With an average of <b>400 downloads per month,</b> Trivio 
            has amassed nearly 10k downloads in just over two years.</p><br/>
            <p>As I have never paid for advertising, all downloads come 
            from users searching for a Jeopardy maker in the iOS App Store.</p>
          </div>
          <div className="metric">
            <div style={{'display': 'flex'}} className="title">
              <h1>5.3k</h1>
              <p className="p1">Accounts Made</p>
            </div>
            <p>Out of 9.4k downloaders, 5.3k of them made accounts — a <b>56%
            conversion rate</b> (admittedly low).</p><br/>
            <p>However, since adding options to sign in with Google and 
            Apple in January 2023, Trivio's conversion rate <b>has risen to 
            71%.</b></p>
          </div>
          <div className="metric">
            <div style={{'display': 'flex', 'translate': '-5px'}} className="title">
              <h1>1.2k</h1>
              <p className="p1">Sets built</p>
            </div>
            <p>Estimating conservatively, it takes about an hour to build a 
            Trivio set and about half an hour to play it.</p><br/>
            <p>If those numbers are true, users have spent a total of <b>108,000 
            minutes</b> either building or playing Trivio sets.</p>
          </div>
        </div>
        <div className="secondary-metrics">
          <div className="session-data-tiles">
            <div style={{'borderRight': '1px solid #322F53'}} className="tile">
              <h1>28.6k</h1>
              <h2>TOTAL SESSIONS</h2>
            </div>
            <div style={{'borderRight': '1px solid #322F53'}} className="tile">
              <h1>13.6</h1>
              <h2>SESSIONS PER USER</h2>
            </div>
            <div className="tile">
              <h1>11.9%</h1>
              <h2>DAY 7 RETENTION</h2>
            </div>
          </div>
          <div className="set-makeup-graphic">
            <img src={hoveredMakeupElement} alt="Proportions" />
            <div className="set-makeup-text">
              <h2>SET MAKEUP</h2>
              <h3>51% Personal Trivia</h3>
              <p 
                className="p2"
                onMouseEnter={() => setHoveredMakeupElement(SetMakeup26)}
                onMouseLeave={() => setHoveredMakeupElement(SetMakeup51)} >26% General Knowledge</p>
              <p 
                className="p2"
                onMouseEnter={() => setHoveredMakeupElement(SetMakeup9)}
                onMouseLeave={() => setHoveredMakeupElement(SetMakeup26)}>9% Religious</p>
              <p 
                className="p2"
                onMouseEnter={() => setHoveredMakeupElement(SetMakeup8)}
                onMouseLeave={() => setHoveredMakeupElement(SetMakeup51)}>8% Education</p>
              <p 
                className="p2"
                onMouseEnter={() => setHoveredMakeupElement(SetMakeup6)}
                onMouseLeave={() => setHoveredMakeupElement(SetMakeup51)}>6% Workplace</p>
            </div>
          </div>
        </div>
      </div>
      <div className="act2-title-section">
        <div className="section-wrapper">
          <h2>Act Two</h2>
          <p>Where to Take Trivio From Here</p>
        </div>
      </div>
      <div className="act2-chapter1">
        <div className="titles">
          <h5>ACT 2, CHAPTER 1</h5>
          <h6>A Teaching Moment for Trivio</h6>
        </div>
        <div className="paragraphs">
          <p>Trivio's unexpected popularity allowed me to seriously consider  
          <b> monetization,</b> specifically targeting teachers. Compared to 
          other users of Trivio who may use the app sporadically, teachers are 
          more likely to find continuous value in a quiz gaming app, motivated by 
          their ongoing need for student engagement and the cyclical nature of 
          their jobs. In its current state, Trivio lacks many of the qualities that 
          would make it an ideal in-class learning tool, so I began thinking of 
          improvements I could make to attract more teachers to the platform.</p><br/>
          <p>For inspiration, I looked to <b>Kahoot!</b>, the live quiz platform 
          that has proven itself in the ed-tech gaming space. In this chapter, 
          I will closely analyze Kahoot's success and apply my findings to the 
          design of Trivio's upcoming premium product.</p>
        </div>
      </div>
      <div className="act2-chapter1-audit">
        <div className="audit-wrapper">
          <div className="titles">
            <h4>Competitive Analysis: High-level Analysis of Kahoot!</h4>
            <p>Identifying the strengths and weaknesses of Kahoot's business model 
            and product</p>
          </div>
          <div className="interactive-content">
            <div 
              className={auditDisplayOption === "Business" ? "visual" : "visual shifted"}>
              {auditDisplayOption === "Business" ? (
                <a href="https://simplywall.st/stocks/no/media/ob-kahot/kahoot-shares/past" target="_blank" rel="noopener noreferrer">
                  <img src={KahootAuditBad} alt="Happy Teacher Playing Kahoot!" />
                </a>
              ) : (
                <img src={KahootAuditGood} alt="Happy Teacher Playing Kahoot!" />
              )}
            </div>
            <div className="content-text">
              <div className="content-heading">
                <div className="kahoot-branding">
                  <img src={KahootLogo} alt="Kahoot! Logo"/>
                  <h2>KAHOOT!</h2>
                </div>
                <div className="option-picker">
                  <h2
                    onClick={() => setAuditDisplayOption("Business")}
                    className={auditDisplayOption === "Business" ? "focused" : "unfocused"}
                  >Business</h2>
                  <img style={{'padding': '0px 20px'}} src={LargeForwardSlash} alt="/"/>
                  <h2
                    onClick={() => setAuditDisplayOption("Product")}
                    className={auditDisplayOption === "Product" ? "focused" : "unfocused"}
                  >Product</h2>
                </div>
              </div>
              {auditDisplayOption === "Business" &&
                <div className="paragraphs">
                  <p>Even with over $150 million in annual revenue as of March 2023, 
                  Kahoot's total earnings are <b>barely in the black</b>, 
                  as seen in the earnings and revenue history chart to the left.</p><br/>
                  <p>Though Kahoot has been dominant in the educational technology 
                  market for a decade now, it has spent most of its life facing 
                  financial uncertainty — like many other companies that start as a 
                  free service, Kahoot <b>struggled for years</b> to identify valuable 
                  premium features that would drive revenue.</p><br/>
                  <p>The lesson to be learned here is that <b>freemium is a balancing 
                  act:</b> make the free plan too bad, and users will never use your 
                  product again. Make the free plan too good (like Kahoot!), and 
                  you'll rob the user of any reason to upgrade.</p>
                </div>
              }
              {auditDisplayOption === "Product" &&
                <div className="paragraphs">
                  <p>Making a kahoot game is <b>streamlined.</b> Teachers can source 
                  their sets from Kahoot!'s enormous database, which boasts “51 million 
                  kahoot games on any topic imaginable.”</p><br/>
                  <p>Playing games in class is <b>engaging.</b> Students are happy to 
                  engage with class material, welcoming the lighthearted competition 
                  and the break from ordinary work.</p><br/>
                  <p>In-game data is <b>insightful.</b> During the game, teachers get 
                  access to quick data about how well their students grasp each question 
                  on the quiz.</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="act2-chapter1-considerations">
        <div className="content-wrapper">
          <div className="titles">
            <h4>Key Considerations</h4>
            <p>Developing solutions to address Trivio's biggest obstacles</p>
          </div>
          <div className="obstacles">
            <div style={{
                'paddingRight': '80px'
              }} className="obstacle">
              <h5>OBSTACLE 1</h5>
              <h6>Teachers won't use a mobile app in the classroom.</h6><br/><br/>
              <p><b>Explanation:</b> Most classrooms in American public schools are 
              equipped with a computer projector, which make web apps best-suited 
              for classroom settings.</p><br/>
              <p><b>Solution:</b> Trivio will have to make an entrance into the 
              teaching space with an <b>all-new web app.</b> New and existing features 
              will have to be redesigned and adapted for desktop browsers.</p>
            </div>
            <div style={{
                'paddingRight': '80px', 
                'paddingLeft': '80px',
                'borderLeft': '1px solid #2C2A41',
              }} className="obstacle">
              <h5>OBSTACLE 2</h5>
              <h6>Jeopardy is not ideal for over three contestants</h6><br/><br/>
              <p><b>Explanation:</b> In Kahoot!, every student can answer every question. 
              In Jeopardy, only one student can respond per clue, yielding less 
              engagement with material.</p><br/>
              <p><b>Solution:</b> Trivio will default to being <b>played in teams </b> 
              of three. When students collaborate on responses, total 
              engagement with each clue remains high, even in large groups.</p>
            </div>
            <div style={{
                'paddingRight': '80px', 
                'paddingLeft': '80px',
                'borderLeft': '1px solid #2C2A41',
              }} className="obstacle">
              <h5>OBSTACLE 3</h5>
              <h6>Teachers already have enough quiz gaming options.</h6><br/><br/>
              <p><b>Explanation:</b> As they're already familiar with various classroom quiz games like Kahoot! and Quizlet 
              Live, teachers might not have the need or capacity to adopt another 
              learning game.</p><br/>
              <p><b>Solution:</b> Trivio is <b>well-differentiated</b> against other quiz 
              games. The Jeopardy format is more vocal in comparison, players will not guess 
              the correct answers, and team-play lends itself to increased collaboration.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="act2-chapter2">
        <div className="plan-previews">
					<img src={TrivioFreePlan} alt="Features in Trivio's Free Tier"/>
					<img src={TrivioPlusPlan} alt="Features in Trivio's Premium Tier"/>
        </div>
        <div className="text">
          <div className="headers"> 
            <h5>ACT 2, CHAPTER 2</h5>
            <h6>Designing Trivio's Freemium Model</h6>
          </div>
          <div className="paragraphs">
            <p>I've designed Trivio's freemium plan to feature live games hosted from 
            a desktop, accessible via mobile <b>in-game buzzers</b>, much like Kahoot!. The 
            free version limits users to five buzzers, suitable for small groups or 
            for team-based play in larger settings. This offers an improvement over 
            free Jeopardy-like platforms that lack buzzer functionality.</p><br/>
            <p>Teachers may find the free plan useful but will likely prefer upgrading to 
            facilitate individual student participation. For non-teachers, the free plan will suffice 
            as most Trivio users build sets which are designed to be played in groups of 
            five or less.</p><br/>
            <p>The remainder of Act Two will be dedicated to demonstrating how Trivio's 
            premium, team-based product will look when played in a classroom setting.</p>
          </div>
        </div>
      </div>
			<div className="act2-chapter2-wireframes">
				<div className="content-wrapper">
					<div className="titles">
						<h4>Wireframe Gallery: Live Games Desktop View</h4>
						<p>Redesigning the mobile gameplay interface for web</p>
					</div>
					<div className="interactive-content">
						<div className="slide-content">
							<img src={DesktopWireframe} alt="The Trivio Board's Desktop Interface (Slide)"/>
						</div>
						<div className="annotations">
							<h2>THE BOARD</h2>
							<div style={{'marginTop': '-5px'}} className="annotation">
								<p><b>The Header</b> contains all information that is not directly related 
                to gameplay. </p>
							</div>
							<div style={{'marginTop': '25px'}} className="annotation">
								<p><b>The Teams Siderail</b> serves both as a leaderboard and as a command 
                center for the host to run the game. The siderail clearly displays all 
                actions required for the game to go forward so that the host and players 
                know what to do at all times.</p>
							</div>
							<div style={{'paddingTop': '214px'}} className="annotation">
								<p><b>The Board</b> is an expanded version of the mobile app's scrolling 
                board. When a user selects a clue, that clue replaces the board, while 
                the siderail remains as it is.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="act2-chapter2-buzzer">
				<div className="titles">
					<h4>UI Showcase: Designing a Teams-Based Buzzer</h4>
					<p>Click through each panel below to step through the screens of the buzzer</p>
				</div>
				<div className="interactive-view">
					<div className="selection-panels">
						<div 
              onClick={() => setPreBuzzerDisplay("Game Code Entry")} 
              className={buzzerDisplay === "Game Code Entry" ? "panel focused" : "panel unfocused"}>
							<img 
                src={buzzerDisplay === "Game Code Entry" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Game Code Entry" ? "Selected" : "Deselected"}/>
							<div className={"panel-label"}>
								<p className="p1">Game Code Entry</p>
								<p className="p2">Player is prompted to enter the game code</p>
							</div>
						</div>
						<div 
            onClick={() => setPreBuzzerDisplay("Active Buzzer")} 
            className={buzzerDisplay === "Active Buzzer" ? "panel focused" : "panel unfocused"}>
							<img 
                src={buzzerDisplay === "Active Buzzer" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Active Buzzer" ? "Selected" : "Deselected"}/>
							<div className={"panel-label"}>
								<p className="p1">Active Buzzer</p>
								<p className="p2">Default view for main buzzer button</p>
							</div>
						</div>
						<div 
              onClick={() => setPreBuzzerDisplay("Winning Buzzer")} 
              className={buzzerDisplay === "Winning Buzzer" ? "panel focused" : "panel unfocused"}>
							<img 
                src={buzzerDisplay === "Winning Buzzer" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Winning Buzzer" ? "Selected" : "Deselected"}/>
							<div 
                className="panel-label">
								<p className="p1">Winning Buzzer</p>
								<p className="p2">Displayed when player wins buzzer race</p>
							</div>
						</div>
						<div
              onClick={() => setPreBuzzerDisplay("Team Win Buzzer")} 
              className={buzzerDisplay === "Team Win Buzzer" ? "panel focused" : "panel unfocused"}> 
							<img 
                src={buzzerDisplay === "Team Win Buzzer" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Team Win Buzzer" ? "Selected" : "Deselected"}/>
							<div className={"panel-label"}>
								<p className="p1">Team Win Buzzer</p>
								<p className="p2">Displayed when teammate wins buzzer race</p>
							</div>
						</div>
						<div 
              onClick={() => setPreBuzzerDisplay("Wager Prompt")} 
              className={buzzerDisplay === "Wager Prompt" ? "panel focused" : "panel unfocused"}> 
							<img 
                src={buzzerDisplay === "Wager Prompt" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Wager Prompt" ? "Selected" : "Deselected"}/>
							<div className={"panel-label"}>
								<p className="p1">Wager Prompt</p>
								<p className="p2">Interface for user to enter a wager amount</p>
							</div>
						</div>
						<div 
              onClick={() => setPreBuzzerDisplay("Final Response Team Poll")} 
              className={buzzerDisplay === "Final Response Team Poll" ? "panel-last focused" : "panel-last unfocused"}> 
							<img 
                src={buzzerDisplay === "Final Response Team Poll" ? SelectedCircleOption : DeselectedCircleOption} 
                alt={"Option " + buzzerDisplay === "Final Response Team Poll" ? "Selected" : "Deselected"}/>
							<div className={"panel-label"}>
								<p className="p1">Final Response Team Poll</p>
								<p className="p2">Displayed during Final Jeopardy</p>
							</div>
						</div>
					</div>
					<div className="annotated-buzzer">
						<img 
              className={activeBuzzerClass}
              src={buzzerDisplaySourceMap[buzzerDisplay]}
              alt="Live Buzzer Page 1"/>
            <div style={{ display: 'none' }}>
              {Object.keys(buzzerDisplaySourceMap).map((key, index) => (
                <img 
                  key={index} 
                  src={buzzerDisplaySourceMap[key]} 
                  alt={`Hidden ${key}`} 
                />
              ))}
            </div>
					</div>
					<Act2BuzzerShowcaseAnnotations buzzerDisplay={buzzerDisplay} activeClass={activeAntBuzzClass} />
				</div>
			</div>
			<div className="act2-chapter3">
				<div className="content-wrapper">
					<div className="titles">
						<h5>ACT 2, CHAPTER 3</h5>
						<h6>A Future User's Journey</h6>
					</div>
					<div className="paragraphs">
						<p>In this chapter and the following slideshow, we will <b>revisit Miss 
						Tina,</b> the high school world history teacher from Act One. Miss Tina 
						recently upgraded to Trivio Plus, and we'll observe her as she trial 
						runs the new web interface with her high school class.</p><br />
						<p>Since I anticipate that <b>teams mode</b> will be the most popular 
						method of gameplay, Tina's user journey will consist of splitting 
						up her 30-student class into three teams of 10 players each. The 
						desktop view reflects what Tina is seeing, and the mobile view 
						represents what her students are seeing at each step along 
						the process.</p>
					</div>
				</div>
			</div>
			<div className="act2-chapter3-slideshow">
				<div className="slideshow-wrapper">
					<div className="titles">
						<h4>User Journey Slideshow: Live Game Logistics</h4>
						<p>Use the arrows below to navigate through the deck</p>
					</div>
					<div className="slide-content">
						<div className="images">
							<img 
                className={activeClass2}
                style={{'paddingRight': '40px'}}
                src={act2DesktopSlides[act2SlideIndex]}
                alt={"Desktop Slide " + act2SlideIndex + 1} />
							<img
                className={activeClass2}
                src={act2MobileSlides[act2SlideIndex]}
                alt={"Mobile Slide " + act2SlideIndex + 1} />
              {act2SlideIndex < act2DesktopSlides.length - 1 &&
                <div style={{'display': 'none'}}>
                  <img 
                    src={act2DesktopSlides[act2SlideIndex + 1]}
                    alt={"Desktop Slide " + act2SlideIndex + 2} />
                  <img
                    src={act2MobileSlides[act2SlideIndex + 1]}
                    alt={"Mobile Slide " + act2SlideIndex + 2} />
                </div>
              }
						</div>
						<div className={`annotations ${activeAnnotationClass2}`}>
							<div className="line" />
							<div className="paired-text">
								<h2>DESKTOP</h2>
								<Act2SlideshowDesktopAnnotations act2SlideIndex={act2SlideIndex} />
							</div>
							<div className="line" />
							<div className="paired-text">
								<h2>MOBILE</h2>
								<Act2SlideshowMobileAnnotations act2SlideIndex={act2SlideIndex} />
							</div>
							<div className="line" />
						</div>
					</div>
				</div>
				<div className="slideshow-controls">
          <div 
            style={act2SlideIndex === 0 ? {'pointerEvents': 'none', 'opacity': 0.4, 'user-select': 'none'} : {}}
            onClick={() => act2SlideIndex !== 0 && setAct2NewSlideIndex(act2NewSlideIndex - 1)}
            className="previous-arrow">
            {(act2SlideIndex !== 0 && act2SlideIndex !== 11) &&
              <div className="arrow-labels">
                <p className="p1">Previous</p>
                <p><b>{act2SlideIndex !== 0 && act2SlideTitles[act2SlideIndex - 1]}</b></p>
              </div>
            }
            <div 
              className={"stacked-arrow-buttons"}>
              <img src={ArrowBackButtonUnfocused} alt="Back Arrow Button (disabled)"/>
              <img src={ArrowBackButtonFocused} alt="Back Arrow Button (enabled)"/>
            </div>
          </div>
          <div className="current-slide-labels">
            <p style={{'opacity': 0.6}}>Slide {act2SlideIndex + 1}/10</p>
            <p style={{'marginTop': '5px'}}><b>{act2SlideTitles[act2SlideIndex]}</b></p>
          </div>
          <div 
            style={act2SlideIndex === 9 ? {'pointerEvents': 'none', 'opacity': 0.4, 'user-select': 'none'} : {}}
            onClick={() => act2SlideIndex !== 9 && setAct2NewSlideIndex(act2NewSlideIndex + 1)}
            className="next-arrow">
            <div 
              className="stacked-arrow-buttons">
              <img src={ArrowForwardButtonUnfocused} alt="Forward Arrow Button (disabled)"/>
              <img src={ArrowForwardButtonFocused} alt="Forward Arrow Button (enabled)"/>
            </div>
            {(act2SlideIndex !== 0 && act2SlideIndex !== 9) &&
              <div className="arrow-labels">
                <p className="p1">Next</p>
                <p><b>{(act2SlideIndex !== 9) && act2SlideTitles[act2SlideIndex + 1]}</b></p>
              </div>
            }
          </div>
        </div>
			</div>
			<div className="reflections">
				<h2>Reflections</h2>
				<div className="paragraphs">
					<p>I'm very fortunate that my side project from college became 
					used by thousands of people almost unintentionally. This app 
					represents many of the reasons why I fell in love with software 
					development. I will never forget how euphoric I felt when I 
					finally got past all of the steps in the App Store review 
					process and saw the app with my name on it in the iOS App 
					Store. It's still surreal to me that features I design and 
					code are used by complete strangers who share my appreciation 
					for Jeopardy.</p><br/>
					<p>The lessons I've learned developing Trivio from start to 
					finish have been invaluable for whatever future products I 
					will decide to build next. If I indeed have a future 
					building apps, I will always remember Trivio as the 
					foundational first project that showed me how to love 
					every step of the process.</p>
				</div>
			</div>
			<Footer footerColor="var(--trivio-footer)"/>
    </div>
  );
};

export default Trivio;

const Act1SlideshowLHAnnotations = (props) => {
  return (
    <div className={`lefthand-annotations ${props.activeClass}`}>
      {props.act1SlideIndex === 0 &&
        <div>
          <div style={{'marginTop': 99}} className="annotation">
            <p><b>The "My Sets" Section</b> takes priority over the view hierarchy, 
            as most Trivio users visit the app to build and play their own sets.</p>
          </div>
          <div style={{'marginTop': 89}} className="annotation">
            <p><b>Community-sourced sets</b> allow Tina and other new users 
            to dive right into Trivio without necessarily having built a 
            set of their own.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 1 &&
        <div>
          <div style={{'marginTop': 120}} className="annotation">
            <p><b>The Progress Bar</b> gives users a sense of what they've built so far 
            and how much is left to build.</p>
          </div>
          <div style={{'marginTop': 83}} className="annotation">
            <p><b>Round Customization.</b> Trivio's sets default to one round with 
            six categories, but Tina wants her game to have two rounds, the second 
            one slightly shorter.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 2 &&
        <div>
          <div style={{'marginTop': 145}} className="annotation">
            <p><b>The Board's Design</b> gives users get a sense of how the game 
            will look as they're building it.</p>
          </div>
          <div style={{'marginTop': 95}} className="annotation">
            <div className="vertical-annotation-content lhs">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>CTA Icons</b> guide Tina to the next 
              step. In this case, Tina decides to add Medieval Asia for 600.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 3 &&
        <div>
          <div style={{'marginTop': 112}} className="annotation">
            <p><b>The Clue</b> in the set builder mirrors the in-game clue. The header  
            is composed of the category name and the value.</p>
          </div>
          <div style={{'marginTop': 149}} className="annotation">
            <p><b>The Background</b> is the board with a Gaussian blur applied to 
            it, evoking a sense that the clue presented is temporarily overlain 
            onto the board.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 4 &&
        <div>
          <div style={{'marginTop': 90}} className="annotation">
            <p><b>The WVC</b>, or Wager-valued clue, is Trivio's non-copyrighted 
            way of saying “Daily Double.” In Jeopardy, Daily Doubles are special 
            clues which allow contestants to assign a wagered point value to 
            the clue.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 5 &&
        <div>
          <div style={{'marginTop': 150}} className="annotation">
            <p><b>Final Clue</b> is Trivio-speak for “Final Jeopardy,” where 
            contestants wager up to their full score on the last clue after 
            seeing its category.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 6 &&
        <div>
          <div style={{'marginTop': 150}} className="annotation">
            <p><b>The Current User's Sets</b> are visually differentiated from 
            other public sets to let users quickly discern which sets are their 
            own.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 7 &&
        <div>
          <div style={{'marginTop': 98}} className="annotation">
            <p><b>The Action Bar</b> gives users the option to study a given set with 
            Trivio's flashcards feature, built specifically to accommodate 
            educational sets.</p>
          </div>
          <div style={{'marginTop': 121}} className="annotation">
            <p><b>The Contestants Panel</b> allows users to add up to five contestants 
            in a mobile game.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 8 &&
        <div>
          <div style={{'marginTop': 83}} className="annotation">
            <p><b>Team 1</b> has the board, so they will be choosing the next clue.</p>
          </div>
          <div style={{'marginTop': 125}} className="annotation">
            <div className="vertical-annotation-content lhs">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina asks Team 1</b> which clue 
              they'd like to pick, and they choose Medieval Asia for 400.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 9 &&
        <div>
          <div style={{'marginTop': 161}} className="annotation">
            <p><b>The Header</b> uses the language of Jeopardy to describe the current 
            clue: “I'll take Category for 400, please.”</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 10 &&
        <div>
          <div style={{'marginTop': 160}} className="annotation">
            <p><b>The Downward Chevron</b> allows users to return to the board. 
            Additionally, users can swipe down on the clue card to go back.</p>
          </div>
          <div style={{'marginTop': 52}} className="annotation">
            <p><b>The Response</b> gets displayed below a collapsed clue to attract
            attention to itself.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 11 &&
        <div>
          <div style={{'marginTop': 90}} className="annotation">
            <p><b>When the game finishes</b>, the teams see how they stacked up 
            against each other.</p>
          </div>
        </div>
      }
    </div>
  );
};

const Act1SlideshowRHAnnotations = (props) => {
  return (
    <div className={`righthand-annotations ${props.activeClass}`}>
      {props.act1SlideIndex === 0 &&
        <div>
          <div style={{'marginTop': 181}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>The Build Prompt</b> is visually 
              distinct from the rest of the homepage. After quickly exploring 
              other sets to get a feel for Trivio, Tina decides to build a set 
              of her own.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 1 &&
        <div>
          <div style={{'marginTop': 73}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>The Forward Button</b> lights up, 
              telling Tina that she can move on to the next step.</p>
            </div>
          </div>
          <div style={{'marginTop': 170}} className="annotation">
            <p><b>Privacy Settings</b> always default to private, but users can 
            optionally make their sets available to all Trivio users.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 2 &&
        <div>
          <div style={{'marginTop': 92}} className="annotation">
            <p><b>The Forward Button</b> does not activate until at least one clue 
            has been written per category.</p>
          </div>
          <div style={{'marginTop': 252}} className="annotation">
            <p><b>Clue Previews</b> clearly display the point value, two lines 
            of the clue, and one line of the response.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 3 &&
        <div>
          <div style={{'marginTop': 237}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina writes the response</b> in a text field that always 
              stays visible, whether or not the keyboard is showing.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 4 &&
        <div>
          <div style={{'marginTop': 73}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina moves on</b> after selecting European Empires 
              for 800 as her WVC.</p>
            </div>
          </div>
          <div style={{'marginTop': 222}} className="annotation">
            <p><b>The Outlined Clue</b> represents the WVC. Once Tina has 
            selected one, she can move onto the next step.</p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 5 &&
        <div>
          <div style={{'marginTop': 121}} className="annotation">
            <p><b>The Progress Bar</b> reflects that Tina has finished all 
            required steps. She can now traverse backwards and forwards freely 
            to make any edits she'd like.</p>
          </div>
          <div style={{'marginTop': 110}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina publishes her set</b>, ready to
              see how it looks in the gameplay interface.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 6 &&
        <div>
          <div style={{'marginTop': 145}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina's New Set</b> appears in the homepage after she's built 
              it. She decides to tap the card to play the game with her class.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 7 &&
        <div>
          <div style={{'marginTop': 215}} className="annotation">
            <p><b>Category Previews</b> give users who did not build this set 
            an idea of its contents without having to play the game.</p>
          </div>
          <div style={{'marginTop': 45}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina Taps the Play CTA</b> after adding three teams.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 8 &&
        <div>
          <div style={{'marginTop': 154}} className="annotation">
            <p><b>The Category Names</b> are not interactive, so their opacity 
            is slightly decreased to signal the user to tap on the clue cells
            below, instead.</p>
          </div>
          <div style={{'marginTop': 92}} className="annotation">
            <p><b>Played Clues</b> lose their number and have their background 
            opacity turned down. Holding an empty cell for 2 seconds brings 
            it back. </p>
          </div>
        </div>
      }
      {props.act1SlideIndex === 9 &&
        <div>
          <div style={{'marginTop': 118}} className="annotation">
            <p><b>The Timer</b> begins, so the teams race to respond to the clue. 
            Tina decides to have students raise their hands to buzz in.</p>
          </div>
          <div style={{'marginTop': 155}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina shows the response</b> when 
              a team has answered correctly or when the time is up.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 10 &&
        <div>
          <div style={{'marginTop': 84}} className="annotation">
            <p><b>Team 2</b> got the response right, so they now have the board.</p>
          </div>
          <div style={{'marginTop': 220}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina grades the teams</b> who 
              responded. Team 1 guessed wrong, then Team 2 got it right.</p>
            </div>
          </div>
        </div>
      }
      {props.act1SlideIndex === 11 &&
        <div>
          <div style={{'marginTop': 398}} className="annotation">
            <div className="vertical-annotation-content">
              <img src={MissTinaThumbnailPortrait} alt="Miss Tina Small Portrait"/>
              <p style={{'marginTop': 10}}><b>Tina finishes the game</b>, happy to 
              see how excited her class was about world history.</p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const Act2BuzzerShowcaseAnnotations = (props) => {
  return (
    <div className={props.activeClass}>
      {props.buzzerDisplay === "Game Code Entry" &&
        <div className="annotations">
          <p style={{'marginTop': '68px'}}><b>The Game Code Entry Page</b> takes inspiration from numerous applications 
          which require a game code to enter, such as Kahoot! and Jackbox Games.</p>
          <p style={{'marginTop': '180px'}}><b>The Text Field and Enter Button</b> are front and center on the page. 
          All buzzer elements are designed as simply as possible so as to not confuse 
          younger users.</p>
          <p style={{'marginTop': '49px'}}><b>The App Store Download Button</b> serves as an advertisement for the Trivio 
          iOS app, and only gets displayed to iPhone users.</p>
        </div>
      }
      {props.buzzerDisplay === "Active Buzzer" &&
        <div className="annotations">
          <p style={{'marginTop': '180px'}}><b>The Buzzer Scoreboard</b> is a side-by-side 
          view of the current player's score and their team's score. The use of the 
          forward slash shows that the player's score is a fraction of the total 
          team score. </p>
          <p style={{'marginTop': '77px'}}><b>The Buzzer</b> is in the active state 
          when a clue's narration has ended. During competitive games, the buzzer will 
          likely be active for an extremely short window, as many contestants are 
          vying to win.</p>
        </div>
      }
      {props.buzzerDisplay === "Winning Buzzer" &&
        <div className="annotations">
          <p style={{'marginTop': '163px'}}><b>The Player's Name</b> changes appearance 
          to symbolize that it's their turn to speak up. The crown icon, mirrored by 
          the buzzer, also gets displayed on the host's screen.</p>
          <p style={{'marginTop': '100px'}}><b>The Buzzer Field</b> turns green, 
          telling the player that they've won the buzzer race. If the correct 
          response is given, all points will be rewarded to the player's team, 
          and their subscore will increase as well.</p>
        </div>
      }
      {props.buzzerDisplay === "Team Win Buzzer" &&
        <div className="annotations">
          <p style={{'marginTop': '164px'}}><b>The Team Name</b> turns golden when 
          someone from the user's team wins the buzzer race. The UI of this page 
          aims to provide a sense of comraderie while maintaining an air of 
          competition.</p>
          <p style={{'marginTop': '60px'}}><b>The Buzzer Field</b> turns 
          muted blue, but its contents change. This signifies to users 
          that while they didn't win the buzzer race, they should be happy for 
          their teammate who will potentially earn points for their team.</p>
        </div>
      }
      {props.buzzerDisplay === "Wager Prompt" &&
        <div className="annotations">
          <p style={{'marginTop': '194px'}}><b>The Score Label</b> changes to only 
          show the team's score, as that is the score that the player will be 
          wagering from.</p>
          <p style={{'marginTop': '97px'}}><b>The Wager Slider</b> offers the player 
          easy control over how much to wager. To foolproof this interface, the user 
          can also type their wager into the text field atop the slider.</p>
        </div>
      }
      {props.buzzerDisplay === "Final Response Team Poll" &&
        <div className="annotations">
          <p style={{'marginTop': '110px'}}><b>The Final Response Team Poll</b> gives 
          teammates a silent, democratic way to deliberate on a response to the Final 
          Jeopardy clue.</p>
          <p style={{'marginTop': '75px'}}><b>Players vote</b> for the top response. 
          Each player only has one vote, but can change their vote as many times as 
          they'd like before time runs out.</p>
          <p style={{'marginTop': '166px'}}><b>Users propose a response</b> using the 
          text field on the bottom of the page. There is no limit to how many 
          responses a user can propose.</p>
        </div>
      }
    </div>
  );
};

const Act2SlideshowDesktopAnnotations = (props) => {
  return (
    <div>
      {props.act2SlideIndex === 0 &&
        <p>The waiting room in team play mode is designed to look best with 
        large groups. Settings are easily accessible to the host, and 
        instructions on how to join the game are also clear for players.</p>
      }
      {props.act2SlideIndex === 1 &&
        <p>When the board is displayed, the player who has the board is 
        prompted to pick a clue. In this vertical arrangement of teams, 
        the team with the board is displayed first, followed by the 
        other teams which are sorted by their scores.</p>
      }
      {props.act2SlideIndex === 2 &&
        <p>When the clue is first displayed, the narration begins and 
        the timer starts counting down from 10. When the narration ends, 
        players' buzzers are activated.</p>
      }
      {props.act2SlideIndex === 3 &&
        <p>The Buzzer Race has been won by Lauren on Team 3, so Tina 
        must grade her response. Lauren guesses “The Western Roman Empire.” </p>
      }
      {props.act2SlideIndex === 4 &&
        <p>Unfortunately, Lauren's answer was incorrect, so Tina marks 
        her accordingly and Team 3 loses 600 points. When the host 
        marks a player incorrect, the buzzer race opens up again, 
        as shown on the next slide.</p>
      }
      {props.act2SlideIndex === 5 &&
        <p>The Buzzer race returns to its active buzzer display, 
        waiting for another player to buzz in.</p>
      }
      {props.act2SlideIndex === 6 &&
        <p>As the siderail shows, David has won the buzzer race, 
        narrowly edging out Ken and James.</p>
      }
      {props.act2SlideIndex === 7 &&
        <p>As the correct response is indeed The Holy Roman Empire, 
        Tina marks David correct, and the board now belongs to Team 1.</p>
      }
      {props.act2SlideIndex === 8 &&
        <p>Tina reveals the response to reinforce the correct answer 
        for the class, and moves on to the board.</p>
      }
      {props.act2SlideIndex === 9 &&
        <p>The board shows that European Empires for 600 has now 
        been played, and that it's David's turn to pick a clue.</p>
      }
    </div>
  );
};

const Act2SlideshowMobileAnnotations = (props) => {
  return (
    <div>
      {props.act2SlideIndex === 0 &&
        <p>After seeing the game code entry page shown in the UI showcase above, 
        players are asked to enter their nickname before joining. Because Tina 
        has chosen to assign teams randomly, players do not need to choose 
        their own teams.</p>
      }
      {props.act2SlideIndex === 1 &&
        <p>The deactivated buzzer shows any time the user is not directly 
        involved with the game. In this case, Brian on Team 2 has the 
        board, and the rest of the class sees the deactivated buzzer as 
        they wait for him to pick a clue.</p>
      }
      {props.act2SlideIndex === 2 &&
        <p>David's buzzer activates, so he buzzes in hoping to win.</p>
      }
      {props.act2SlideIndex === 3 &&
        <p>David's buzzer shows that he's been beaten by Lauren, 
        which is reflected by the buzzer race on the teacher's screen.</p>
      }
      {props.act2SlideIndex === 4 &&
        <p>(No visual changes) David's screen tells him that his buzzer is about to be 
        reactivated, as Lauren just responded incorrectly.</p>
      }
      {props.act2SlideIndex === 5 &&
        <p>David is very confident about the answer to this clue, and 
        he's not going to let victory escape from his fingertips again, 
        so he buzzes in as soon as he can. </p>
      }
      {props.act2SlideIndex === 6 &&
        <p>David sees that this is his moment, and confidently says 
        “What is The Holy Roman Empire?”</p>
      }
      {props.act2SlideIndex === 7 &&
        <p>The mobile interface rewards David for his erudition, and 
        he is encouraged to see his individual and team score 
        increase as well.</p>
      }
      {props.act2SlideIndex === 8 &&
        <p>(No mobile UI changes) The mobile interface rewards David for his 
        erudition, and he is encouraged to see his individual and team score 
        increase as well.</p>
      }
      {props.act2SlideIndex === 9 &&
        <p>David gets to pick the clue, and after he does, the cycle 
        will continue until all the clues have been played.</p>
      }
    </div>
  );
};