import React from 'react';
import './Footer.css';
import LinkedinLogoBW from '../../photos/Footer/LinkedinLogoBW.png';

const Footer = (props) => {
  return (
    <div className="footer" style={{
      backgroundColor: props.footerColor,
    }}>
      <div className="footer-content">
        <div className="footer-text">
          <div className="contact">
            <p className="p1">
              CONTACT
            </p>
            <p className="p2">
              david.s.chen@berkeley.edu
            </p>
          </div>
          <div className="copyright">
            <p className="p1">
              COPYRIGHT
            </p>
            <p className="p2">
              © 2023
            </p>
          </div>
          <div className="last-updated">
            <p className="p1">
              LAST UPDATED
            </p>
            <p className="p2">
              September 14th, 2023
            </p>
          </div>
        </div>
        <div className="contact-icons">
          <a href="https://www.linkedin.com/in/david-chen-748a47149/">
            <img src={LinkedinLogoBW} alt="Linkedin Logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
